.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.lds-loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-loader div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: white;
  animation: lds-loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-loader div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-loader div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-loader div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-loader {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}
