@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@800&display=swap');

@font-face {
  font-family: 'Manrope-Regular';
  src: local('Manrope'), url(./fonts/Manrope-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Manrope-Medium';
  src: local('Manrope'), url(./fonts/Manrope-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Manrope-Bold';
  src: local('Manrope'), url(./fonts/Manrope-Bold.ttf) format('truetype');
}

#root {
  background: #f6f6f6;
}

.App {
  background: url('./img/Top blobs.png') no-repeat top center;
  min-height: 100vh;
  overflow-y: scroll;
  display: flex;
  -webkit-overflow-scrolling: touch; /* enables “momentum” (smooth) scrolling */
  flex-direction: column;
  justify-content: space-between;

  // Scroll freezing problem
  // https://github.com/ionic-team/ionic-v1/issues/155#issuecomment-462133457
  // https://stackoverflow.com/questions/42486150/weird-blocking-on-ios-safari-on-scroll
  @media only screen and (min-width: 600px) {
    height: 100vh;
  }
}

$teal: #23efbe;

body {
  margin: 0;
}

header img {
  width: 150px;
  margin: auto;
}

.grecaptcha-badge {
  display: none !important;
}

.centered {
  text-align: center;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

a {
  text-decoration: none;
}

.qr-cta {
  margin: 1rem auto;
  font-weight: bold;
  font-size: 2rem;
  max-width: 60vh;
  text-align: center;
}

.qr-code {
  max-width: 40vh;
  margin: 2rem auto;
  display: block;
}

button {
  width: 100%;
  max-width: 400px;
  padding: 10px;
  border: none;
  background-color: white;
  color: black;
  font-size: 15px;
  font-family: 'Poppins';
  font-weight: 600;
  cursor: pointer;
  margin-top: 1em;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

input:focus::placeholder {
  color: transparent;
}

.redirecting {
  align-self: center;
  width: 100px;
  color: 'white';
}

@keyframes ellipsis {
  to {
    width: 20px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 20px;
  }
}

input {
  width: 100%;
  height: 40px;
  font-size: 14px;
  padding: 0 10px 0 10px;
  outline: none;
  border-radius: 5px;
  border: none;
}

.error {
  color: red;
  text-align: center;
}

.link {
  margin-top: 1em;
  color: white;
  cursor: pointer;
}

h1,
h2,
h4 {
  font-family: 'Poppins';
}

.teal {
  color: $teal;
}
